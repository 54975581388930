import React, { useState, FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PurpleAccentHero from "../components/PurpleAccentHero";
import Banner from "../components/Banner";
import Button from "../components/Button";
import { TextField } from "@material-ui/core";
import { API_URL } from "../utils/constants";
import axios from "axios";
import CookiesSnackbar from "../components/CookiesSnackbar";
import { useTypography } from "../styles/Typography";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/styles";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner";

const useStyles = makeStyles({
  titleHeadingGradientWrapper: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)",
    "@media(max-width: 959px)": {
      background: "rgba(255, 255, 255, 0) 67.89%)",
    },
  },
  titleHeadingText: {
    marginLeft: 100,
    maxWidth: 460,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    color: "rgba(0, 0, 0, 0.8)",
    letterSpacing: "0.25px",
    "@media (max-width: 959px)": {
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
      padding: 24,
    },
  },
  body: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    marginBottom: 48,
    padding: "24px 100px",
    gap: "48px",
    "@media (max-width: 1059px)": {
      display: "flex",
      flexDirection: "column",
      padding: 24,
    },
  },
  bodySubbmitedStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
  },
  bodyInfo: {
    flex: 1,
    marginBottom: "auto",
  },
  bodyContent: {
    flex: 1,
    width: 600,
    "@media (max-width: 1200px)": {
      width: "unset",
    },
  },
  bodySubmittedContent: {
    width: 600,
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  bodyTitle: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 32,
    lineHeight: 4,
    letterSpacing: 0.25,
    marginBottom: "16px !important",
  },
  feedbackBox: {
    lineHeight: 1.25,
  },
  feedback: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 24,
    lineHeight: 1.25,
    letterSpacing: 0.25,
    marginBottom: "16px !important",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  bodyInfoText: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
  },
  changeOfServiceValueUl: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
});

const WorkshopPage: FC = () => {
  const classes = useStyles();
  const typo = useTypography();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      desktopHeroImage: file(
        relativePath: { eq: "workshop/workshop-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileHeroImage: file(
        relativePath: { eq: "workshop/workshop-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopBannerImage: file(relativePath: { eq: "banner-desktop.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileBannerImage: file(relativePath: { eq: "banner-mobile.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const nameChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };
  const emailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const companyChange = (event) => {
    event.preventDefault();
    setCompany(event.target.value);
  };
  const jobChange = (event) => {
    event.preventDefault();
    setJobTitle(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let error = false;

    if (!name) {
      setNameError(true);
      error = true;
    } else {
      setNameError(false);
    }
    if (!email) {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }
    if (!company) {
      setCompanyError(true);
      error = true;
    } else {
      setCompanyError(false);
    }

    const body = {
      name,
      email,
      company,
      jobTitle,
    };
    if (!error) {
      try {
        const response = await axios.post(`${API_URL}/RequestDemo`, body);
        if (response?.data?.data) {
          setSubmitted(true);
          ReactGA.event({
            category: "Demo request",
            action: "Submitted",
          });
        }
      } catch (err) {
        console.log("submit error:", err);
      }
    }
  };

  return (
    <Layout>
      <SEO title="Workshop" />
      <DiscoverMoreBanner />
      <PurpleAccentHero
        desktopImage={data.desktopHeroImage.childImageSharp.fluid}
        mobileImage={data.mobileHeroImage.childImageSharp.fluid}
        alt="Man smiling in front of his computer in an open office environment"
        backgroundPosition="right"
      >
        <div className={classes.titleHeadingGradientWrapper}>
          <section className={classes.titleHeadingText}>
            <h1 className={typo.h4} style={{ marginBottom: 8 }}>
              Schedule your free Microsoft 365 Change as a Service Solution
              Discovery & Design Workshop
            </h1>
            <h2 className={typo.h6}>
              Nulia Works continuously gathers data on how organizations and
              employees are using Copilot & Microsoft 365
            </h2>
          </section>
        </div>
      </PurpleAccentHero>
      <section
        className={submitted ? classes.bodySubbmitedStyle : classes.body}
      >
        {!submitted && (
          <div className={classes.bodyInfoText}>
            <div className={classes.bodyInfo}>
              <h2 className={typo.h4} style={{ marginBottom: 36 }}>
                Why a Change as a Service Solution Discovery & Design Workshop
                is a must have for your organization
              </h2>
              <p>
                A Microsoft 365 Change as a Service Solution Discovery & Design
                Workshop identifies your specific Microsoft 365 & Copilot
                adoption challenges and opportunities.
              </p>
              <p>
                We help you personalize an actionable solution to drive Copilot
                & All of Microsoft 365 adoption and sustained change, all
                aligned to the goals which unlock business value for your
                organization.
              </p>
            </div>
            <div className={classes.bodyInfo}>
              <h2 className={typo.h4} style={{ marginBottom: 36 }}>
                The value of a Change as a Service Solution Discovery & Design
                Workshop
              </h2>
              <ul className={classes.changeOfServiceValueUl}>
                <li>
                  Develop a personalized roadmap to drive adoption and unlock
                  the value of your Copilot & Microsoft 365 investment,
                  identifying your specific Microsoft 365 adoption challenges
                  and opportunities. This personalized roadmap will drive
                  adoption and sustained change, all aligned to your
                  organization’s prioritized goals.
                </li>
                <li>
                  Build a business case for investing in a Change as a Service
                  solution utilizing our innovative Total Economic Impact model
                  to quantify opportunities to realize more value from your
                  Microsoft 365 investment.
                </li>
              </ul>
            </div>
          </div>
        )}
        <div
          className={
            submitted ? classes.bodySubmittedContent : classes.bodyContent
          }
        >
          {!submitted ? (
            <div>
              <h2>
                YOU CAN REALIZE MORE VALUE FROM YOUR MICROSOFT 365 INVESTMENT.
                LET US SHOW YOU HOW.
              </h2>
              <form className={classes.form} noValidate autoComplete="off">
                <TextField
                  label="Name (Required)"
                  style={{ width: "100%", marginBottom: 38 }}
                  value={name}
                  error={nameError}
                  onChange={nameChange}
                  autoComplete="name"
                />
                <TextField
                  label="Email (Required)"
                  style={{ width: "100%", marginBottom: 38 }}
                  value={email}
                  error={emailError}
                  onChange={emailChange}
                  autoComplete="email"
                />
                <TextField
                  label="Company (Required)"
                  style={{ width: "100%", marginBottom: 38 }}
                  value={company}
                  error={companyError}
                  onChange={companyChange}
                  autoComplete="organization"
                />
                <TextField
                  label="Title"
                  style={{ width: "100%", marginBottom: 38 }}
                  value={jobTitle}
                  onChange={jobChange}
                  autoComplete="organization-title"
                />
                <Button style={{ width: 224, height: 36 }} onClick={onSubmit}>
                  Submit
                </Button>
              </form>
            </div>
          ) : (
            <div className={classes.feedbackBox}>
              <h3 className={classes.feedback}>
                Your request for a free, personalized Microsoft 365 Adoption
                Solution Discovery & Design Workshop has been received. A member
                of our team will reach out to you soon with next steps.
              </h3>
              <h3 className={classes.feedback}>Thank you.</h3>
            </div>
          )}
        </div>
      </section>
      <CookiesSnackbar />
    </Layout>
  );
};

export default WorkshopPage;
